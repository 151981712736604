import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, hover } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.button`
  padding: 12rem 28rem;
  border-radius: 30rem;
  background: ${colors.concrete};
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    background 300ms,
    box-shadow 300ms;
  color: ${colors.nandor};
  cursor: pointer;

  ${hover(css`
    background: ${rgba(colors.outerSpace, 0.2)};
  `)}

  &:focus-visible {
    ${focusVisible};
  }
`;

export const ButtonIcon = styled.div`
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background 150ms,
    color 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10rem;
`;
