import { rgba } from 'polished';
import { FC, memo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/dropdowns/DropdownMenu/DropdownMenu';
import { useCopyStore, useGlobalStore } from 'store';
import { colors } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import { useReleases } from './hooks/useReleases';

import * as Styled from './WhatsNewModal.styles';

export interface WhatsNewModalProps {}

const WhatsNewModal: FC<WhatsNewModalProps> = () => {
  const locale = useCopyStore(s => s.locale);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const releases = useReleases();
  const [currentRelease, setCurrentRelease] = useState(releases[0]);

  const close = () => {
    closeModal('whatsNew');
  };

  return (
    <ConfigModal
      title={`What's New ${currentRelease.version} - 
      ${new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
      }).format(currentRelease.date)}`}
      color={currentColor}
      onCloseClick={close}
      paddingBottom={40}
      buttonProps={{
        label: 'Close',
        onClick: close,
        color: currentColor,
        icon: 'check',
      }}
    >
      <Styled.Wrapper>
        <Styled.Releases>
          <Styled.OtherReleases>
            <DropdownMenu>
              <DropdownMenuTrigger style={{ color: rgba(colors.white, 0.5) }}>
                See Previous Releases...
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {releases.map(({ version, date }) => (
                  <DropdownMenuItem
                    key={version}
                    onSelect={() =>
                      setCurrentRelease(
                        releases.find(r => r.version === version) ?? releases[0]
                      )
                    }
                  >
                    {version} -{' '}
                    {new Intl.DateTimeFormat(locale, {
                      year: 'numeric',
                      month: 'long',
                    }).format(date)}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </Styled.OtherReleases>
        </Styled.Releases>

        <Styled.Content>
          <ReactMarkdown>{currentRelease.changes}</ReactMarkdown>
        </Styled.Content>
        <Styled.Disclaimer>
          DISCLAIMER: If you notice any issues or have any feedback/questions -
          please use the{' '}
          <span style={{ fontWeight: 'bold' }}>SEND FEEDBACK</span> button that
          you can locate in the dropdown menu in the top right corner of the
          app.
        </Styled.Disclaimer>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(WhatsNewModal);
