export interface Release {
  version: string;
  date: Date;
  changes: string;
}

export const useReleases = () => {
  // Important: Append new releases at the beginning of the array
  const releases: Release[] = [
    {
      version: '0.22.0',
      date: new Date('2025-03-14'),
      changes: `
### What’s New
#### Cashflow
* Added new recurring payment rule for payment x days after last event in the cashflow ends: e.g, you have multiple events in cashflow, 8h, 5d & 4h, 10d, and the payment comes out x days after the 15th day.
* Invoice label added to cashflow.
* Exit icon added to set kick-off date modal.
#### General 
* In-app notifications for platform updates.
### Bugs Fixed
Project List Page
* “New+” button clipping issues.
`,
    },
    {
      version: '0.21.0',
      date: new Date('2025-02-20'),
      changes: `
### What’s New
#### Project
* In project settings, “Groups” changed to “Add to Group” for clarity of adding projects to project groups
#### Budget 
* “Confirm” button is disabled until a selection is made in the “Add Row” modal
#### Invoice Modal
* “Date” column changed to “Issue Date” for clarity of when the invoice was issued
#### Cashflow
* Cashflow timeline is no longer populated as default in a new project after adding resource rows, it is now optional to populate allocation based on quantity
### Bugs Fixed
#### Header
* Adding markup % to a row doesn't add the markup amount to the markup budget total but instead the % amount
#### Cashflow
* Calculations for the balances within the bottom stats of the cashflow were inconsistent
* “Money-In” reflected a cumulative sum of all invoice amounts thus far per invoice payment date instead of the actual invoice payment amount on the given date
#### Admin
* Role Category dropdown was empty in the modal if it was the first time a user opened it in a session.
* When adding category rows in Roles page, they were appended to the bottom but the page became cut-off where you couldn’t access the category row
* Column sort UI wasn’t functional–it has been removed for the time being
* Moving roles across sections on the Roles page misplaced the rows and didn’t append them to the bottom of the newly assigned category
* In the Roles page, if internal rate was set to 0, the field showed “undefined”
* Unable to delete roles from the Roles page
* Editing a field value in the Roles page caused a category row glitch
* Edits to fields in the roles section of the admin panel take up to 5 seconds to show
`,
    },
    {
      version: '0.20.0',
      date: new Date('2025-02-10'),
      changes: `
### What’s New
* Project
    * Moving a a project from ‘pitch’ status into ‘production’ changes the version name of the budget from “Pitch” to “Production”
* Budget
    * “Add Row” functionality in each category added to seamlessly allocate new rows in categories.
* Cashflow
    * Creating an invoice via the cashflow panel opens the same invoice modal as adding a budget in the header to keep the behaviour of adding invoices consistent; new invoices will be added to the bottom of the list.
### Bugs Fixed
* Project
    * If all metrics in the header are enabled, the bottom of the page will be cut-off and user won’t be able to scroll to the very bottom of the page.
* Cashflow
    * Editing an event via the modal caused a duplicate event to be made in the same row if date range overlaps with an already existing event in the row resulting in seeing two time entries for the same row.
* Admin
    * Admin-level users no longer receive email notifications for verifying added resources, roles, clients, by another admin user. 
`,
    },
    {
      version: '0.19.0',
      date: new Date('2025-01-10'),
      changes: `
### What’s New

* Distinguish between INT and EXT balance and resources in the Cashflow 
* Header customisation is saved across sessions
* Password rules introduced when creating a new account to increase account security
* Minor UI fixes
### Bugs Fixed
#### Projects
* Adding a project to a group or creating a new group redirects user to an incorrect URL
#### Notifications and Alerts
* Links within in-app notifications take users to a non-existent page
* Email notifications notifications not triggered for the following requests:
    * BoB Access Requests
        * Admins do not currently receive access request emails 
        * Admins do not currently receive access request emails
    * Resource Requests
        * Admin does not current receive a resource request email
        * User does not receive a feedback email
    * Vendor Requests
        * Admin does not receive vendor request email
        * User does not receive a feedback email
    * Client Requests
        * Admin does not receive a client request email
        * User does not receive a feedback email
    * Brand Requests
        * Admin does not currently receive a request email
        * User does not receive a feedback email
    * Role Requests
        * User does not receive a feedback email
    * Vendor Service Requests
        * Admin does not currently receive a request email
        * User does not receive a feedback email
#### Admin Panel
* Roles–unused items cannot be resolved.
* Brands–unused items cannot be resolved.
* Clients–unused items cannot be resolved.
* Vendors–unused items cannot be resolved.
* Vendor services–unused items cannot be resolved.
* In-app notifications not triggered for the following requests:
    * Resources
    * Vendors
    * Vendor Services
    * Clients
    * Brands
    * Roles
* Counters display incorrectly for the following alerts:
    * Resource duplicates, requests, and unused
    * Vendor duplicates, requests, and unused
    * Client requests
    * Brand requests
    * Role requests
      `,
    },
    {
      version: '0.18.0',
      date: new Date('2024-06-01'),
      changes: `
### What’s New  
#### Project Cashflow  
* Introduced **Project Cashflow** for better financial tracking.  
* Users will receive a **notification** if a related **Teamdeck Project** is found for one of their projects.  
* For Teamdeck projects, users can now see if the **burn is outdated** compared to Teamdeck data.  
#### Features & Enhancements  
* Added a **carbon description** feature.  
* Introduced **quick filters** for **Job Number Requests** and **Job Closure Requests** in project navigation.  
* Implemented **configurable User Access Rights**.  
* Added the option to **watch/stop watching projects**.  
### Bugs Fixed  
* Fixed sorting for the **status column** in the projects list.  
* Fixed a bug where users in **different timezones** saw different dates in the invoice list popup.  
* Fixed an issue where the **datepicker in history log** would not display properly.  
* Fixed a bug preventing **JAS sheet uploads** for Hosting projects.  
* Fixed an issue where **404 pages** were not displaying correctly.  
### Improvements  
* **Automatic redirect** from the maintenance page when maintenance is over.  
* Updated **MFR Net** header.  
* Renamed **'Production Versions'** to **'Change Orders'**, which can now be merged into the main budget.  
* Made the **'Client' field mandatory** before requesting a job number.  
      `,
    },
    {
      version: '0.17.0',
      date: new Date('2024-04-01'),
      changes: `
### What’s New
#### Job Closure Requesting Flow
* Introduced a structured process for requesting job closures.
#### Payments
* A new **'Payments'** view available for the Finance Team.
* A new **'Payments'** tab in the project invoice window, displaying all payments related to the project.
#### Project Management
* Added the ability to filter project rows.
* Integrated Slackbot support.
* Customization options added for **MFR Report** and **Client-Facing Budget Report**.
* Introduced a **Teamdeck Report**.
* Added a new field in the project invoice window: **Invoice Number**.
### Bugs Fixed
* Fixed an issue where **burn summary** was not calculated properly.
* Fixed a bug preventing the **invoice date** from being adjusted in the budget table.
* Fixed a bug where some **email notifications** were incorrectly tied to the same email thread.
### Improvements
* Users can now input both **dot (.) and comma (,) as decimal separators**.
* Improved **error handling** across the platform.
      `,
    },
  ];

  return releases;
};
