import { css, FlattenSimpleInterpolation, keyframes } from 'styled-components';

import { pulse, spin } from './animations';
import { colors, fonts } from './theme';

// Fix Safari mobile fix, where vh values are wrongly calculated
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export const setVh = (value: number) => `calc(var(--vh, 1vh) * ${value})`;

export const hover = (content: FlattenSimpleInterpolation) => css`
  &:active {
    ${content}
  }
  &:hover {
    ${content}
  }
`;

export const setVisibility = (isVisible: boolean) => css`
  transition: opacity 0.2s ease-out;

  ${!isVisible
    ? `
    opacity: 0;
    pointer-events: none;
  `
    : ''}
`;

export const setTypography = (
  category: keyof typeof fonts.scale,
  // eslint-disable-next-line
  locale = process.env.DEFAULT_LOCALE
) => css`
  ${Object.entries(fonts.scale[category])
    .map(([key, value]) => `${key}: ${value};`)
    .join('\n')}
`;

// Bezier curves
export const easeInSine = 'cubic-bezier(0.470, 0.000, 0.745, 0.715)';
export const easeOutSine = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
export const easeInOutSine = 'cubic-bezier(0.445, 0.050, 0.550, 0.950)';
export const easeInQuad = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)';
export const easeOutQuad = 'cubic-bezier(0.250, 0.460, 0.450, 0.940)';
export const easeInOutQuad = 'cubic-bezier(0.455, 0.030, 0.515, 0.955)';
export const easeInCubic = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';
export const easeOutCubic = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)';
export const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';
export const easeInQuart = 'cubic-bezier(0.895, 0.030, 0.685, 0.220)';
export const easeOutQuart = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';
export const easeInOutQuart = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)';
export const easeInQuint = 'cubic-bezier(0.755, 0.050, 0.855, 0.060)';
export const easeOutQuint = 'cubic-bezier(0.230, 1.000, 0.320, 1.000)';
export const easeInOutQuint = 'cubic-bezier(0.860, 0.000, 0.070, 1.000)';
export const easeInExpo = 'cubic-bezier(0.950, 0.050, 0.795, 0.035)';
export const easeOutExpo = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)';
export const easeInOutExpo = 'cubic-bezier(1.000, 0.000, 0.000, 1.000)';
export const easeInCirc = 'cubic-bezier(0.600, 0.040, 0.980, 0.335)';
export const easeOutCirc = 'cubic-bezier(0.075, 0.820, 0.165, 1.000)';
export const easeInOutCirc = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)';
export const easeInBack = 'cubic-bezier(0.600, -0.280, 0.735, 0.045)';
export const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)';
export const easeInOutBack = 'cubic-bezier(0.680, -0.550, 0.265, 1.550)';

export const eases = {
  easeInSine,
  easeOutSine,
  easeInOutSine,
  easeInQuad,
  easeOutQuad,
  easeInOutQuad,
  easeInCubic,
  easeOutCubic,
  easeInOutCubic,
  easeInQuart,
  easeOutQuart,
  easeInOutQuart,
  easeInQuint,
  easeOutQuint,
  easeInOutQuint,
  easeInExpo,
  easeOutExpo,
  easeInOutExpo,
  easeInCirc,
  easeOutCirc,
  easeInOutCirc,
  easeInBack,
  easeOutBack,
  easeInOutBack,
} as const;

export const getEasingValues = (easing: keyof typeof eases) =>
  eases[easing].match(/(\d+\.?\d*)/g)?.map(parseFloat);

// Animations
export const spinAnimation = () => css`
  animation: ${spin()} 1s infinite linear;
`;

export const pulseAnimation = (transform?: string, duration = 3.5) => css`
  animation: ${pulse(transform)} ${duration}s ${easeOutCubic} forwards;
`;

// Other mixins
export const fullscreenWhite = () => css`
  position: fixed;
  width: 100%;
  height: ${setVh(100)};
  top: 0;
  left: 0;
  background: ${colors.white};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

export const fixedPageButton = () => css`
  position: fixed;
  bottom: 50rem;
  left: 550rem;
  z-index: 10;
`;

export const hideScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const focusVisible = css`
  box-shadow: inset 0 0 0 2px ${colors.malibu};
`;

export const markdownStyles = css`
  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.33em;
    margin-bottom: 0.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 0.6em;
    margin-bottom: 0.6;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h4 {
    display: block;
    margin-top: 0.9em;
    margin-bottom: 0.9em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  a {
    color: ${colors.malibu};
    text-decoration: underline;
  }
  ol {
    display: block;
    list-style: decimal;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  p {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;
  }
  strong {
    font-weight: bold;
  }
  code {
    color: ${colors.mountainMeadow};
  }
`;

const slideDown = keyframes`
  0% {
    transform: translateY(-20rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const slideDownAnimation = css`
  animation: ${slideDown} 150ms;
`;
