import { Dispatch, FC, memo, SetStateAction } from 'react';

import CardDropdown from 'components/dropdowns/CardDropdown/CardDropdown';
import InfoIcon from 'components/InfoIcon/InfoIcon';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import Switch from 'components/Switch/Switch';
import { useHideProjectVisibility } from 'hooks/useHideProjectVisibility';
import { useProjectAccess } from 'hooks/useProjectAccess';
import { useOrganization } from 'queries/organizations';
import { useCopyStore } from 'store';
import { Brand, Client, GeoArea, Project, Vertical } from 'utils/api.types';
import { colors } from 'utils/styles/theme';

import { useJobNumber } from '../hooks/useJobNumber';
import BrandField from './Brand/Brand';
import BudgetStatus from './BudgetStatus/BudgetStatus';
import Clients from './Client/Client';
import JobNumberField from './JobNumber/JobNumber';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import Templates, { TemplateOptions } from './Templates/Templates';
import Territory from './Territory/Territory';
import VerticalField from './Vertical/Vertical';

import * as Styled from '../ProjectModal.styles';

export interface MainTabProps {
  project?: Project;
  activeVerticalId?: Vertical['id'];
  setActiveVerticalId: Dispatch<SetStateAction<Vertical['id'] | undefined>>;
  activeBrandId?: Brand['id'];
  setActiveBrandId: Dispatch<SetStateAction<Brand['id'] | undefined>>;
  activeClientId?: Client['id'];
  setActiveClientId: Dispatch<SetStateAction<Client['id'] | undefined>>;
  activeTerritoryId?: GeoArea['id'];
  setActiveTerritoryId: Dispatch<SetStateAction<GeoArea['id'] | undefined>>;
  activeStatus: Project['status'];
  activeMode: Project['mode'];
  projectNameField: ReturnType<typeof useField>;
  isSensitiveField: [boolean, Dispatch<SetStateAction<boolean>>];
  isTestField: [boolean, Dispatch<SetStateAction<boolean>>];
  jobNumberField: ReturnType<typeof useJobNumber>;
  selectedTemplate: TemplateOptions | undefined;
  setSelectedTemplate: Dispatch<SetStateAction<TemplateOptions | undefined>>;
}

const MainTab: FC<MainTabProps> = ({
  project,
  activeBrandId,
  activeVerticalId,
  activeClientId,
  activeStatus,
  activeMode,
  activeTerritoryId,
  setActiveBrandId,
  setActiveVerticalId,
  setActiveClientId,
  setActiveTerritoryId,
  selectedTemplate,
  setSelectedTemplate,

  projectNameField,
  jobNumberField,
  isSensitiveField,
  isTestField,
}) => {
  const { data: organization } = useOrganization();
  const copy = useCopyStore(s => s.copy);
  const projectId = project?.id;
  const isEditProjectModal = !!projectId;

  const { getCanEditProject } = useProjectAccess();
  const canEditProject = getCanEditProject(project) || !projectId;

  const { isHideButtonVisible, isTestButtonVisible } = useHideProjectVisibility(
    { project }
  );

  const {
    duplicateError,
    isJobNumberRequired,
    jobNumber,
    jobNumberError,
    setJobNumber,
  } = jobNumberField;

  const [isSensitive, setIsSensitive] = isSensitiveField;
  const [isTest, setIsTest] = isTestField;

  const [projectName, setProjectName, , projectNameError] = projectNameField;

  const isExperientialBudgetingEnabled =
    organization?.allow_bottom_up_budgeting;
  const isTemplateDropdownVisible =
    !isEditProjectModal && isExperientialBudgetingEnabled;

  return (
    <Styled.TabWrapper>
      <Styled.Row>
        <BudgetStatus
          activeStatus={activeStatus}
          activeMode={activeMode}
          jobClosureRequested={project?.job_closure_requested}
          jobNumberRequested={project?.job_number_requested}
        />
      </Styled.Row>
      {isTemplateDropdownVisible && (
        <Styled.Row>
          <Templates
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </Styled.Row>
      )}
      <Styled.Row
        data-cy="project-modal__job-number-wrapper"
        hasMorePadding={!!duplicateError}
      >
        <JobNumberField
          projectId={projectId}
          activeMode={activeMode}
          jobNumber={jobNumber}
          setJobNumber={setJobNumber}
          isJobNumberRequired={isJobNumberRequired}
          duplicateError={duplicateError}
          jobNumberError={jobNumberError}
          canEditProject={canEditProject}
        />
        <Styled.FieldSpace />
        <VerticalField
          projectId={projectId}
          activeVerticalId={activeVerticalId}
          setActiveVerticalId={setActiveVerticalId}
        />
        <Styled.FieldSpace />
        <Territory
          projectId={projectId}
          activeTerritoryId={activeTerritoryId}
          setActiveTerritoryId={setActiveTerritoryId}
          canEditProject={canEditProject}
        />
      </Styled.Row>
      <Styled.Row>
        <Clients
          projectId={projectId}
          activeClientId={activeClientId}
          setActiveClientId={setActiveClientId}
          canEditProject={canEditProject}
        />
        <Styled.FieldSpace />
        <BrandField
          projectId={projectId}
          activeBrandId={activeBrandId}
          setActiveBrandId={setActiveBrandId}
          canEditProject={canEditProject}
        />
      </Styled.Row>
      <Styled.Row>
        <FormInput
          value={projectName}
          onChange={setProjectName}
          placeholder={copy.app.projectModal.projectName}
          required={true}
          error={projectNameError}
          dataCy="project-modal__name-field"
          disabled={!canEditProject}
        />
        {isTestButtonVisible && (
          <>
            <Styled.FieldSpace />
            <Styled.SwitchWrapper data-intro="test-project">
              <CardDropdown
                button={
                  <Styled.SwitchInfoIcon>
                    <InfoIcon theme="dark" />
                  </Styled.SwitchInfoIcon>
                }
                cardProps={{
                  title: copy.app.projectModal.testProjectLabel,
                  text: copy.app.projectModal.testProjectTooltip,
                  type: 'info',
                  cardStyles: {
                    background: colors.black,
                  },
                }}
                clickType="hover"
              />
              <Switch
                label={copy.app.projectModal.testProjectLabel}
                checked={isTest}
                onCheckedChange={setIsTest}
                labelPlacement="top"
                dataCy="project-modal__test-switch"
              />
            </Styled.SwitchWrapper>
          </>
        )}
        {isHideButtonVisible && (
          <>
            <Styled.FieldSpace />
            <Styled.SwitchWrapper data-intro="hidden-project">
              <CardDropdown
                button={
                  <Styled.SwitchInfoIcon>
                    <InfoIcon theme="dark" />
                  </Styled.SwitchInfoIcon>
                }
                cardProps={{
                  title: copy.app.projectModal.hideProjectLabel,
                  text: copy.app.projectModal.hideProjectTooltip,
                  type: 'info',
                  cardStyles: {
                    background: colors.black,
                  },
                }}
                clickType="hover"
              />
              <Switch
                label={copy.app.projectModal.hideProjectLabel}
                checked={isSensitive}
                onCheckedChange={setIsSensitive}
                labelPlacement="top"
                dataCy="project-modal__sensitive-switch"
              />
            </Styled.SwitchWrapper>
          </>
        )}
      </Styled.Row>
      {project && <ProjectInfo projectId={projectId} />}
    </Styled.TabWrapper>
  );
};

export default memo(MainTab);
