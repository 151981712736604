import { FC, memo } from 'react';

import Badge from 'components/Badge/Badge';
import { useCopyStore, useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './ChatToggle.styles';

export interface ChatToggleProps {}

const ChatToggle: FC<ChatToggleProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const { SvgIcon: AlertIcon } = useSvgIcon('alert');
  const openModal = useGlobalStore(s => s.openModal);

  return (
    <Styled.Wrapper onClick={() => openModal('chatModal')}>
      Ask AI{' '}
      <Styled.ButtonIcon>
        <AlertIcon />
      </Styled.ButtonIcon>
      <Badge
        color={ColorNames.mountainMeadow}
        styles={{ marginLeft: '20rem' }}
        size="small"
      >
        {copy.app.new}
      </Badge>
    </Styled.Wrapper>
  );
};

export default memo(ChatToggle);
