import { FC } from 'react';

import {
  Select,
  SelectItem,
  SelectSeparator,
} from 'components/dropdowns/Select/Select';
import { useCopyStore } from 'store';

import * as Styled from './Templates.styles';

const EMPTY_OPTION = 'EMPTY_OPTION';

export enum TemplateOptions {
  EXPERIENTIAL = 'Experiential',
}

export interface TemplatesProps {
  selectedTemplate: TemplateOptions | undefined;
  setSelectedTemplate: (value: TemplateOptions | undefined) => void;
}

const Templates: FC<TemplatesProps> = ({
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const copy = useCopyStore(s => s.copy);

  return (
    <Styled.Wrapper>
      <Select
        placeholder={copy.app.projectModal.selectTemplatePlaceholder}
        label={copy.app.projectModal.selectTemplateLabel}
        value={selectedTemplate}
        onValueChange={value => {
          if (value === EMPTY_OPTION) {
            setSelectedTemplate(undefined);
          }
          if (value === TemplateOptions.EXPERIENTIAL) {
            setSelectedTemplate(TemplateOptions.EXPERIENTIAL);
          }
        }}
      >
        <SelectItem value={EMPTY_OPTION}>
          {copy.app.projectModal.noTemplateOption}
        </SelectItem>
        <SelectSeparator />
        <SelectItem value={TemplateOptions.EXPERIENTIAL}>
          {copy.app.projectModal.experientialTemplateOption}
        </SelectItem>
      </Select>
    </Styled.Wrapper>
  );
};

export default Templates;
