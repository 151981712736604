import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import Datepicker from 'components/datepickers/Datepicker/Datepicker';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { usePrefillCashflowItems } from 'queries/cashflow';
import { useCurrentProject, useUpdateProject } from 'queries/project';
import { useGlobalStore } from 'store';
import { getDateString } from 'utils/formatters';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './CashflowPrefillItemsModal.styles';

export interface CashflowPrefillItemsModalProps {}

const CashflowPrefillItemsModal: FC<CashflowPrefillItemsModalProps> = () => {
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const [kickoffDate, setKickoffDate] = useState<Date>(
    currentProject?.kickoff_date
      ? new Date(currentProject?.kickoff_date)
      : new Date()
  );
  const [isFillItemsSelected, setIsFillItemsSelected] = useState(false);
  const isKickoffDateSet = !!currentProject?.kickoff_date;
  const { mutateAsync: updateProject, isLoading: isProjectUpdateLoading } =
    useUpdateProject();
  const { mutateAsync: prefillCashflowItems, isLoading: isPrefillLoading } =
    usePrefillCashflowItems();

  const close = () => {
    closeModal('cashflowPrefillItems');
  };

  const handleConfirm = async () => {
    if (currentProject && currentVersionId) {
      const kickoffDateChanged =
        getDateString(kickoffDate) !== currentProject?.kickoff_date;
      if (kickoffDateChanged) {
        await updateProject({
          projectId: currentProject?.id,
          project: {
            kickoff_date: getDateString(kickoffDate),
          },
        });
      }

      if (isFillItemsSelected) {
        await prefillCashflowItems({
          projectId: currentProject?.id,
          projectVersionId: currentVersionId,
        });
      }

      close();
    }
  };

  return (
    <ConfigModal
      title={isKickoffDateSet ? 'Change Kickoff Date' : 'Set Kickoff Date'}
      onCloseClick={close}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Datepicker>
          <Datepicker
            onChange={setKickoffDate}
            value={kickoffDate}
            color={currentColor}
            fieldLabel="Kickoff Date"
          />
        </Styled.Datepicker>
        <Styled.Checkbox>
          <Checkbox
            value={isFillItemsSelected}
            setValue={setIsFillItemsSelected}
            variant="square"
            label="Delete existing cashflow and allocate items based on row quantities, starting from the kickoff date."
            labelStyles={{ textAlign: 'start' }}
          />
        </Styled.Checkbox>
        <Styled.Buttons>
          {isKickoffDateSet && (
            <Button
              label="Cancel"
              onClick={close}
              color={ColorNames.nandor}
              icon="close"
            />
          )}

          <Button
            label="Confirm"
            onClick={handleConfirm}
            color={currentColor}
            icon="check"
            loading={isProjectUpdateLoading || isPrefillLoading}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(CashflowPrefillItemsModal);
