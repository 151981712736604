import _merge from 'lodash/merge';
import create from 'zustand';

import { Burn, ProjectRow, ReconcileExternalItem } from 'utils/api.types';

export type BurnFieldsUpdate = Record<
  ProjectRow['id'],
  {
    value?: Burn['value'];
    checkmark?: Burn['checkmark'];
    comment?: Burn['comment'];
    isSourceAutoUpdate?: boolean;
  }
>;

export interface ProjectStore {
  projectView: 'table' | 'cashflow';
  setProjectView: (view: 'table' | 'cashflow') => void;
  markupWarningRowId: ProjectRow['id'] | null;
  setMarkupWarningId: (rowId: ProjectRow['id'] | null) => void;
  burnEditMode: boolean;
  setBurnEditMode: (isEditMode: boolean) => void;
  updatedBurnFields: BurnFieldsUpdate;
  setUpdatedBurnFields: (fields: BurnFieldsUpdate) => void;
  resetUpdatedBurnFields: () => void;
  isTrueMarginVisible: boolean;
  setIsTrueMarginVisible: (value: boolean) => void;
  isMfrVisible: boolean;
  setIsMfrVisible: (value: boolean) => void;
  isPassthroughVisible: boolean;
  setIsPassthroughVisible: (value: boolean) => void;
  isTargetVisible: boolean;
  isMarkupVisible: boolean;
  setIsMarkupVisible: (value: boolean) => void;
  setIsTargetVisible: (value: boolean) => void;
  isBurnCalendarVisible: boolean;
  setIsBurnCalendarVisible: (value: boolean) => void;
  autoAdjustedQuantityRowId: ProjectRow['id'] | null;
  setAutoAdjustedQuantityRowId: (rowId: ProjectRow['id'] | null) => void;
  autoAdjustedBurnRowId: ProjectRow['id'] | null;
  setAutoAdjustedBurnRowId: (rowId: ProjectRow['id'] | null) => void;
  wrapResourcesFloat: ReconcileExternalItem['id'][] | null;
  setWrapResourcesFloat: (ids: ReconcileExternalItem['id'][] | null) => void;
  wrapResourcesTeamdeck: ReconcileExternalItem['id'][] | null;
  setWrapResourcesTeamdeck: (ids: ReconcileExternalItem['id'][] | null) => void;
  tableScrollPosition: number;
  setTableScrollPosition: (value: number) => void;
  isAllocationKeepModeEnabled: boolean;
  setIsAllocationKeepModeEnabled: (value: boolean) => void;
  isCashflowPaymentMode: boolean;
  setIsCashflowPaymentMode: (value: boolean) => void;
  forceEditModeId: ProjectRow['id'] | null;
  setForceEditModeId: (rowId: ProjectRow['id'] | null) => void;
  isSummaryView: boolean;
  setIsSummaryView: (value: boolean) => void;
}

const useProjectStore = create<ProjectStore>(set => {
  return {
    projectView: 'table',
    setProjectView: view => {
      set(() => ({
        projectView: view,
      }));
    },

    markupWarningRowId: null,
    setMarkupWarningId: rowId => {
      set(() => ({
        markupWarningRowId: rowId,
      }));
    },

    burnEditMode: false,
    setBurnEditMode: isEditMode => {
      set(() => ({
        burnEditMode: isEditMode,
      }));
    },

    updatedBurnFields: {},
    setUpdatedBurnFields: fields => {
      set(prev => {
        return {
          updatedBurnFields: _merge({}, prev.updatedBurnFields, fields),
        };
      });
    },
    resetUpdatedBurnFields: () => {
      set(() => ({
        updatedBurnFields: {},
      }));
    },

    isTrueMarginVisible: false,
    setIsTrueMarginVisible: value => {
      set(() => ({
        isTrueMarginVisible: value,
      }));
    },

    isMfrVisible: false,
    setIsMfrVisible: value => {
      set(() => ({
        isMfrVisible: value,
      }));
    },

    isPassthroughVisible: false,
    setIsPassthroughVisible: value => {
      set(() => ({
        isPassthroughVisible: value,
      }));
    },

    isTargetVisible: false,
    setIsTargetVisible: value => {
      set(() => ({
        isTargetVisible: value,
      }));
    },

    isMarkupVisible: false,
    setIsMarkupVisible: value => {
      set(() => ({
        isMarkupVisible: value,
      }));
    },

    isBurnCalendarVisible: false,
    setIsBurnCalendarVisible: value => {
      set(() => ({
        isBurnCalendarVisible: value,
      }));
    },

    autoAdjustedQuantityRowId: null,
    setAutoAdjustedQuantityRowId: rowId => {
      set(() => ({
        autoAdjustedQuantityRowId: rowId,
      }));
    },

    autoAdjustedBurnRowId: null,
    setAutoAdjustedBurnRowId: rowId => {
      set(() => ({
        autoAdjustedBurnRowId: rowId,
      }));
    },

    wrapResourcesFloat: null,
    setWrapResourcesFloat: ids => {
      set(() => ({
        wrapResourcesFloat: ids,
      }));
    },
    wrapResourcesTeamdeck: null,
    setWrapResourcesTeamdeck: ids => {
      set(() => ({
        wrapResourcesTeamdeck: ids,
      }));
    },
    tableScrollPosition: 0,
    setTableScrollPosition: value => {
      set(() => ({
        tableScrollPosition: value,
      }));
    },

    isAllocationKeepModeEnabled: false,
    setIsAllocationKeepModeEnabled: value => {
      set(() => ({
        isAllocationKeepModeEnabled: value,
      }));
    },

    isCashflowPaymentMode: false,
    setIsCashflowPaymentMode: value => {
      set(() => ({
        isCashflowPaymentMode: value,
      }));
    },

    forceEditModeId: null,
    setForceEditModeId: rowId => {
      set(() => ({
        forceEditModeId: rowId,
      }));
    },

    isSummaryView: false,
    setIsSummaryView: value => {
      set(() => ({
        isSummaryView: value,
      }));
    },
  };
});

export default useProjectStore;
