import { useSession } from 'next-auth/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import BaseInput from 'components/inputs/BaseInput/BaseInput';
import { useField } from 'components/inputs/hooks/useField';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { useSocket } from 'hooks/useSocket';

import * as Styled from './Chat.styles';

export interface ChatProps {}

const Chat: FC<ChatProps> = () => {
  const socket = useSocket();
  const [currentMessage, setCurrentMessage] = useField('');
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [events, setEvents] = useState<
    { origin: 'server' | 'client'; message: string }[]
  >([]);
  const messagesWrapper = useRef<HTMLDivElement>(null);
  const { data: session } = useSession();

  const onConnect = useCallback(() => {
    setIsConnected(true);
  }, []);

  const onDisconnect = useCallback(() => {
    setIsConnected(false);
  }, []);

  const scrollToBottom = () => {
    if (messagesWrapper.current) {
      messagesWrapper.current.scrollTo({
        top: messagesWrapper.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const onResponse = useCallback((response: { message: string }) => {
    setEvents(prevEvents => [
      ...prevEvents,
      { origin: 'server', message: response.message },
    ]);
  }, []);

  useEffect(() => {
    socket.connect();
  });

  useEffect(() => {
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('response', onResponse);

    return () => {
      socket.disconnect();
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('response', onResponse);
    };
  }, [onConnect, onDisconnect, onResponse, socket]);

  const handleSendMessage = () => {
    setEvents(prevEvents => [
      ...prevEvents,
      { origin: 'client', message: currentMessage },
    ]);
    socket.emit('message', {
      message: currentMessage,
      accessRights: session?.accessRights,
    });
    setCurrentMessage('');
  };

  useEffect(() => {
    scrollToBottom();
  }, [events]);

  return (
    <Styled.Wrapper>
      <ScrollArea>
        <Styled.Messages ref={messagesWrapper}>
          {events.length === 0 && isConnected && (
            <Styled.Message origin={'server'}>
              How can I help you today?
            </Styled.Message>
          )}
          {events.map((event, index) => (
            <Styled.Message key={index} origin={event.origin}>
              <ReactMarkdown>{event.message}</ReactMarkdown>
            </Styled.Message>
          ))}
        </Styled.Messages>
      </ScrollArea>

      <Styled.BottomSection>
        <Styled.InputWrapper>
          <BaseInput
            onChange={setCurrentMessage}
            value={currentMessage}
            placeholder='Ask me anything about BoB (e.g. "How to add a project.")'
            onEnter={handleSendMessage}
          />
        </Styled.InputWrapper>
        <Styled.SendButton onClick={handleSendMessage}>Send</Styled.SendButton>
      </Styled.BottomSection>
    </Styled.Wrapper>
  );
};

export default Chat;
