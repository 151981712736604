import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { markdownStyles, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

const bottomSectionHeight = '180rem';

export const Wrapper = styled.div`
  width: 1600rem;
  max-width: 1600rem;
  height: ${setVh(70)};
  max-height: ${setVh(70)};
  display: flex;
  flex-direction: column;
`;

export const Messages = styled.div`
  display: flex;
  height: calc(${setVh(70)} - ${bottomSectionHeight});
  max-height: calc(${setVh(70)} - ${bottomSectionHeight});
  padding: 40rem;
  overflow-y: auto;
  flex-direction: column;

  ${markdownStyles};
`;

export const BottomSection = styled.div`
  width: 100%;
  height: ${bottomSectionHeight};
  display: flex;
  gap: 40rem;
  align-items: center;
  padding: 40rem;
`;

export const InputWrapper = styled.div`
  flex: 1;
  border: 1px solid ${rgba(colors.white, 0.5)};
  padding: 20rem;
  border-radius: 20rem;
`;

export const SendButton = styled.button`
  padding: 20rem;
  background: ${colors.nandor};
  color: ${colors.white};
  border-radius: 20rem;
`;

export const Message = styled.div<{ origin: 'server' | 'client' }>`
  ${({ origin }) =>
    origin === 'client'
      ? css`
          background: #444444;
        `
      : css``}
  border-radius: 20rem;
  margin-bottom: 10rem;
  padding: 0 30rem;
  width: fit-content;
  align-self: ${({ origin }) =>
    origin === 'server' ? 'flex-start' : 'flex-end'};
`;
