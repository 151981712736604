import { FC, memo } from 'react';

import Chat from 'components/Chat/Chat';
import { useGlobalStore } from 'store';

import ConfigModal from '../ConfigModal/ConfigModal';

export interface ChatModalProps {}

const ChatModal: FC<ChatModalProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  return (
    <ConfigModal
      title="BoB AI Assistant"
      zeroPadding
      onCloseClick={() => closeModal('chatModal')}
      color={currentColor}
    >
      <Chat />
    </ConfigModal>
  );
};

export default memo(ChatModal);
