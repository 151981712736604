import { useMemo } from 'react';
import { io } from 'socket.io-client';

import { useApiStore } from 'store';

export const useSocket = () => {
  // Not happy about getting the token from apiStore but it's convenient
  const rawToken = useApiStore(s => s.rawToken);

  // "undefined" means the URL will be computed from the `window.location` object
  const URL = process.env.CHAT_URL;

  const socket = useMemo(
    () =>
      io(URL, {
        autoConnect: false,
        extraHeaders: {
          authorization: `bearer ${rawToken}`,
        },
      }),
    [URL, rawToken]
  );

  return socket;
};
